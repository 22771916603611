import { Container, Navbar } from 'react-bootstrap'
import logo from './EWM-Logo.png'

export function NavMenu () {
    return (
      <header>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/">
              <img alt="EWM logo" src={logo} />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>
    );
}

export default NavMenu;