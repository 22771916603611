import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import isEqual from "lodash/isEqual";

const useFetchPost = (baseUrl, params, postObj) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [status, setStatus] = useState();
    const abortController = new AbortController();
    const [statefulParams, setStatefulParams] = useState(params)

    useEffect(()=>{
      if(!isEqual(statefulParams,params)){
        setStatefulParams({...params})
      }
      //eslint-disable-next-line
    },[params])

    useEffect(() => {
      if(baseUrl){
        let token = ''
        let stringifiedParams = ''
        if(params){
          // change from key/value to an object with the key being the key and value being the value
          // turn that object into an array
          // iterate over the array using a map
          for (const [key, value] of Object.entries(params)) {
            if(stringifiedParams===''){
              stringifiedParams += (`?${key}=${value}`);
            }else{
              stringifiedParams += (`&${key}=${value}`);
            }
          }
        }
        fetchData(0,(baseUrl+token+stringifiedParams));
      }

      return ()=>{
        abortController.abort();
      } 
   // eslint-disable-next-line
    }, [baseUrl,postObj,statefulParams]);

    const fetchData = (count, url) => {
      setLoading(true);
      if(count<3){
        fetch(url,{
            signal: abortController.signal,
            method: 'POST',
            headers: {
                'Content-Type':'application/json'
            },
            body: JSON.stringify(postObj)
        })
        .then(res => {
          if(res.status===200){
            setStatus(200)
            return res.json()
          }else if(res.status===400){
            res.text().then(text=>{
              if(text==='The wait operation timed out.'){
                setTimeout(() => {
                  fetchData(count++,url)
                }, 1000);
              }
            })
          }else if(res.status===503||res.status===500){
            setTimeout(() => {
              fetchData(count++,url)
            }, 1000);
            return [];
          }else if(!res.ok){
            res.text().then(text=>{
              if(text==="Database 'Employnet' cannot be opened. It is in the middle of a restore."){
                setTimeout(() => {
                  fetchData(count++,url)
                }, 1000);
              }else{
                setStatus(res.status)
                throw Error(res.statusText)
              }
            })
          }
        })
        .then(data=>{
          setLoading(false);
          setData(data);
        })
        .catch(error => {
          setLoading(false)
          setError(error);
        });
      };
    }
    return { error, loading, data, setData, status };
  };

  useFetchPost.propTypes = {
    url: PropTypes.string.isRequired,
    cookies: PropTypes.object.isRequired,
  }

  export default useFetchPost;