import React from 'react';
import { NavMenu } from './NavMenu';
import Footer from './Footer'
import ErrorBoundary from './ErrorBoundary';

export default function Layout({children}) {
    return (
      <div style={{ 'minHeight':'90vh' }}>
        <ErrorBoundary>
          <NavMenu />
          <div className='content'>
            <ErrorBoundary>
              {children}
            </ErrorBoundary>
          </div>
          <Footer />
        </ErrorBoundary>
      </div>
    );
}
