import React, { useEffect, useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import './ICEEval.css'
import useFetchPost from './useFetchPost';
import { renderToStaticMarkup } from 'react-dom/server'

// Create the HTML templates for the emails using an email editor
// Add those HTML templates to the form submission
// Add necessary plugins to the new project
// Copy pasta the Eval into it
// Deploy and test

function ICEEval() {
  const urlParams = new URLSearchParams(window.location.search);
  const [ICCompany, setICCompany] = useState(urlParams.get('company'));
  const [ICName, setICName] = useState(urlParams.get('name'));
  const [ICEmail, setICEmail] = useState(urlParams.get('email'));
  const [ICPhone, setICPhone] = useState(urlParams.get('phone'));

  const [validated, setValidated] = useState(false);
  const [preivouslyEmployed, setPreviouslyEmployed] = useState();
  const [showLevelOfInstruction, setShowLevelOfInstruction] = useState();
  const [showMoreSchedulingInfo, setShowMoreSchedulingInfo] = useState();
  const [showIncomeDetails, setShowIncomeDetails] = useState();
  const [showProvidedTools, setShowProvidedTools] = useState();
  const [showSelfEmployed, setShowSelfEmployed] = useState();
  const [showLicense, setShowLicense] = useState();
  const [showAmountOfNotice, setShowAmountOfNotice] = useState();
  const [showOnlyClient, setShowOnlyClient] = useState();
  const [showPersonalInvestment, setShowPersonalInvestment] = useState();
  const [showBusinessEmployees, setShowBusinessEmployees] = useState();
  const [showAdvertisementSpecifics, setShowAdvertisementSpecifics] = useState();
  const [postObj, setPostObj] = useState();
  const [ICPostObj, setICPostObj] = useState();
  const { REACT_APP_API_URL } = process.env;
  const { status, loading } = useFetchPost((postObj&&`${REACT_APP_API_URL}/Utilities/SendSMTPEmail`),{to: 'ewmreqpayroll@employnet.com',from:'administrator@employnet.com',subject:'Independent Contractor Evaluation',isHTML:true}, postObj)
  const { status: ICStatus, loading: ICLoading } = useFetchPost((ICPostObj&&`${REACT_APP_API_URL}/Utilities/SendSMTPEmail`),{to: ICEmail,from:'ewmreqpayroll@employnet.com',subject:'Independent Contractor Evaluation',isHTML:true}, ICPostObj)


  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.target;

    if (form.checkValidity() === false) {
        event.stopPropagation();
    }else{
        let labels = document.getElementsByTagName('label')
        let children = [];
        for (let index = 0; index < labels.length; index++) {
            const label = labels[index].textContent;
            const data = form.elements[index].value
            children.push(<p><b>{label}</b><br /> {data}</p>)
        }
        let emailHTML = (<html><body>
            {children}
            <br/>
            <br/>
            <p>EWM Team</p>
            <a href='mailto:ewmreqpayroll@employnet.com'>ewmreqpayroll@employnet.com</a><br/>
            <a href='tel:8315156774'>831-515-6774</a><br/>
            <img src="https://ewm.employnet.com/wp-content/uploads/EWM-Logo-Full-300x48.png" alt="EWM Logo"></img>
            </body></html>)
        setPostObj({message:renderToStaticMarkup(emailHTML)})

        let contractorEmail = (<html><body>
            <h2>{ICName}</h2>
            <p>Thank you for completing the IC Evaluation form; this has been sent to EWM for review. You are almost done! Last step in the evaluation process is to submit your supporting documents to <a href='mailto:ewmreqpayroll@employnet.com'>ewmreqpayroll@employnet.com</a></p>
            <p>Forms needed to send are a copy of your W9 and COI, Articles of Corporation and any other supporting documents to help with evaluation (I.e. business cards, sales material, etc.) </p>
            <br/>
            <br/>
            <p>EWM Team</p>
            <a href='mailto:ewmreqpayroll@employnet.com'>ewmreqpayroll@employnet.com</a><br/>
            <a href='tel:8315156774'>831-515-6774</a><br/>
            <img src="https://ewm.employnet.com/wp-content/uploads/EWM-Logo-Full-300x48.png" alt="EWM Logo"></img>
            </body></html>)
        setICPostObj({message:renderToStaticMarkup(contractorEmail)})

    }
    setValidated(true);
  };

  useEffect(()=>{
    if(status){
        console.log(status)
    }
  },[status])

//   const handleFileEvent = (e) => {
//     const chosenFiles = Array.prototype.slice.call(e.target.files)
//     chosenFiles.forEach(file=>{
//         getBase64(file)
//     })
//     setUploadedFiles(chosenFiles);
//   }

//   function getBase64(file) {
//     var reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = function () {
//       console.log(reader.result);
//     };
//     reader.onerror = function (error) {
//       console.log('Error: ', error);
//     };
//   }

  return (
    <div className='padding margin-top'>
        <Form noValidate validated={validated} onSubmit={handleSubmit} id='ICEForm'>
            <h2>Independent Contractor Evaluation:</h2>
            <p>All required fields are marked with <span>*</span></p>
            <Card className='margin-bottom'>
                <Card.Body>
                    <h4>Contact Information</h4>
                    <p>Please verify all contact information is correct</p>
                    <Form.Group className='margin-bottom' controlId='directWork'>
                        <Form.Label>Name<span>*</span></Form.Label>
                        <Form.Control value={ICName} onChange={(e)=>setICName(e.target.value)} />
                        <Form.Text muted>The name of the person being evaluated</Form.Text>

                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='margin-bottom' controlId='directWork'>
                        <Form.Label>Company<span>*</span></Form.Label>
                        <Form.Control value={ICCompany} onChange={(e)=>setICCompany(e.target.value)} />

                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='margin-bottom' controlId='directWork'>
                        <Form.Label>Email<span>*</span></Form.Label>
                        <Form.Control value={ICEmail} onChange={(e)=>setICEmail(e.target.value)} />

                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className='margin-bottom' controlId='directWork'>
                        <Form.Label>Phone<span>*</span></Form.Label>
                        <Form.Control value={ICPhone} onChange={(e)=>setICPhone(e.target.value)} />

                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                    </Form.Group>
                </Card.Body>
            </Card>
            <Card className='padding margin-bottom'>
                <h3>Worker</h3>
                <Form.Group className='margin-bottom full-width' controlId='profession'>
                    <Form.Label>What is your profession?<span>*</span></Form.Label>
                    <Form.Control
                    required 
                    type='text' 
                    placeholder='Provide title, degree(s) in the profession, license(s)...' 
                    />
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide a Profession</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='margin-bottom' controlId='SOW'>
                    <Form.Label>Please confirm the services to be provided<span>*</span></Form.Label>
                    <Form.Control
                    required 
                    type='text' 
                    placeholder='Provide detail or attach SOW' 
                    />
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide services</Form.Control.Feedback>
                </Form.Group>
            
                <Form.Group className='margin-bottom' controlId='specialSkills'>
                    <Form.Label>What special skills to you have that qualify you to do the work for the client?<span>*</span></Form.Label>
                    <Form.Control
                    required 
                    type='text' 
                    placeholder='Be specific' 
                    />
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide special skills</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='margin-bottom' controlId='workedPreviously'>
                    <Form.Label>Are you currently or have you previously worked in a different capacity for this client?<span>*</span></Form.Label>
                    <Form.Select required onChange={(e)=>setPreviouslyEmployed(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>

                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {preivouslyEmployed==='Yes'&&
                    <Form.Group className='margin-bottom' controlId='hasWorkedPreviously'>
                        <Form.Label>Provide date ranges of employment with client and the capacity in which you worked.<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Example: 10-2-2019 through 12-10-2019. Created website</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='instruction'>
                    <Form.Label>Level of instruction: will you require training to perform the tasks outlined?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowLevelOfInstruction(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showLevelOfInstruction==='Yes')&&
                <Form.Group className='margin-bottom' controlId='levelOfInstruction'>
                    <Form.Label>Confirm the training required and who will be providing it<span>*</span></Form.Label>
                    <Form.Control
                        required 
                        type='text' 
                    />                
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide an explination</Form.Control.Feedback>
                </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='flexibilityOfSchedule'>
                    <Form.Label>Flexibility of Schedule: Did the client confirm a required set schedule for this engagement? <span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowMoreSchedulingInfo(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showMoreSchedulingInfo==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='moreSchedulingInfo'>
                        <Form.Label>Please provide more details about set schedule<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please answer the prompt</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='FullTimeWork'>
                    <Form.Label>Full Time Work: Will this engagement be your only source of income?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowIncomeDetails(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Text id="scheduleHelp" muted>
                        
                    </Form.Text>
                </Form.Group>

                {(showIncomeDetails==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='yesOnlySourceOfIncome'>
                        <Form.Label>Please provide more details about this being your only source of income<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }
                {(showIncomeDetails==='No')&&
                    <Form.Group className='margin-bottom' controlId='notOnlySourceOfIncome'>
                        <Form.Label>Are you receiving income from other sources for providing the same services you will be providing to client?<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='ToolsEquipment'>
                    <Form.Label>Tools and Equipment: Will the client provide any tools and/or equipment for you to perform the services (computer, phone, construction or other equipment, business cards etc.)?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowProvidedTools(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showProvidedTools==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='providedTools'>
                        <Form.Label>Please list what will be provided<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide a list</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='direction'>
                    <Form.Label>Will the client be providing you with diection on how to do the work involved in providing the services?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setPreviouslyEmployed(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                    <Form.Text id="toolsEquipmentHelp" muted>
                        If yes provide details
                    </Form.Text>
                </Form.Group>

                {(preivouslyEmployed==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='notOnlySourceOfIncome'>
                        <Form.Label>Please provide details on previous employment<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='businessForSelf'>
                    <Form.Label>Do you hold yourself out as being in business for yourself?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowSelfEmployed(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showSelfEmployed==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='businessForSelfDetails'>
                        <Form.Label>Please provide details on self-employment<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='workLicense'>
                    <Form.Label>Do you have a license in the work that you will be performing for the client?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowLicense(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showLicense==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='workLicenseDetails'>
                        <Form.Label>What license?<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='leaveWithoutNotice'>
                    <Form.Label>Are you able to leave at any time without notice? <span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowAmountOfNotice(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showAmountOfNotice==='No')&&
                    <Form.Group className='margin-bottom' controlId='leaveWithoutNoticeDetails'>
                        <Form.Label>How much notice are you required to give? <span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }
            </Card>

            <Card className='padding margin-bottom'>
                <h3>Business</h3>
                <Form.Group className='margin-bottom' controlId='companySetup'>
                    <Form.Label>How is your company set up?<span>*</span></Form.Label>
                    <Form.Control
                        required 
                        type='text' 
                        placeholder='S-Corp, C-Corp, etc...' 
                    />
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide placeholder</Form.Control.Feedback>
                    <Form.Text id="companySetupHelp" muted>
                        Attach a copy of your business license and W-9
                    </Form.Text>
                </Form.Group>

                <Form.Group className='margin-bottom' controlId='onlyClient'>
                    <Form.Label>Is this your only client?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowOnlyClient(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showOnlyClient==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='onlyClientDetails'>
                        <Form.Label>Please provide details on this being your only client<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='personallyInvested'>
                    <Form.Label>Do you have a personal investment in your business?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowPersonalInvestment(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showPersonalInvestment==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='personallyInvestedDetails'>
                        <Form.Label>If yes, provide comment for more detailed info<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }

                <Form.Group className='margin-bottom' controlId='employees'>
                    <Form.Label>Does your business have employees?<span>*</span></Form.Label>
                    <Form.Select onChange={(e)=>setShowBusinessEmployees(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showBusinessEmployees==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='employeesDetail'>
                        <Form.Label>How many employees? In which positions?<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                        />                
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide details</Form.Control.Feedback>
                    </Form.Group>
                }
            </Card>

            <Card className='padding margin-bottom'>
                <h3>Business or Worker</h3>
                <Form.Group className='margin-bottom' controlId='projectOrHourly'>
                    <Form.Label>Will compensation be by project or based on hours spent providing services?<span>*</span></Form.Label>
                    <Form.Control
                        required 
                        type='text' 
                    />
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please provide an answer</Form.Control.Feedback>
                    <Form.Text id='projectOrHourlyHelp' muted>
                        Explain
                    </Form.Text>
                </Form.Group>

                <Form.Group className='margin-bottom' controlId='advertisement'>
                    <Form.Label>Do you advertise that you provide the services you will be providing to the client?<span>*</span></Form.Label>
                    <Form.Select required onChange={(e)=>setShowAdvertisementSpecifics(e.target.value)} >
                        <option value="">Select...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                    </Form.Select>
                    <Form.Control.Feedback>Good!</Form.Control.Feedback>
                    <Form.Control.Feedback type='invalid'>Please select Yes/No</Form.Control.Feedback>
                </Form.Group>

                {(showAdvertisementSpecifics==='Yes')&&
                    <Form.Group className='margin-bottom' controlId='advertisementSpecifics'>
                        <Form.Label>How do you advertise?  Provide specifics/copies of ads.  If online, provide links to ads.<span>*</span></Form.Label>
                        <Form.Control
                            required 
                            type='text' 
                            />
                        <Form.Control.Feedback>Good!</Form.Control.Feedback>
                        <Form.Control.Feedback type='invalid'>Please provide an answer</Form.Control.Feedback>
                    </Form.Group>
                }
            </Card>
            {(status===200&&ICStatus===200)?
            <Alert>Successfully submitted!</Alert>
            :
            ((status&&status!==200)&&(ICStatus&&ICStatus!==200))&&
            <Alert variant='warning'>There was an error, please try again.</Alert>
            }
            <Button className='margin-top' type="submit">{(loading||ICLoading)?<Spinner animation='border' size='sm' />:'Submit form'}</Button>
        </Form>
    </div>
  );
}

export default ICEEval;