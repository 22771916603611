import React from 'react';
import './Footer.css'
import Package from '../../package.json'
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const Footer = () =>{

    return (
        <Navbar className='text-center footer'>
            <div className='footer-link-wrapper'>
                <Row className="footer-link">
                    <Col>
                        <a className="text-white" href='https://employnet.com/' target="_blank" rel="noreferrer">
                            employnet.com
                        </a>
                    </Col>
                    {/* <Col>
                        <a className="text-white" href='/Privacy'>
                            Privacy
                        </a>          
                    </Col> */}
                    <Col>
                        <p>App Version: {Package.version}</p>
                    </Col>
                </Row>
                <Row>
                    <div className='text-center'>
                        &copy; {new Date().getFullYear()} Copyright:{' - '}
                        <p>Employnet Workforce Management</p>
                    </div>
                </Row>
            </div>
      </Navbar>
    )
};

export default Footer;